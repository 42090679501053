import React, { useState } from 'react'
import styles from './PortalTable.module.css'
import {ReactComponent as EditIcon} from 'assets/icons/editIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/delete.svg'
import  ExamIcon from 'assets/icons/examCat.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import AddEditOnboardSliderModal from '../AddEditModal/AddEditModal'
import Cookies from 'js-cookie'
import TableLoading from 'components/Global/Elements/TableLoading/TableLoading'

function PortalOnboardSliderTable({tableData, getData, isLoadingData}) {
    const [showAddCategory, setShowAddCategory] = useState(false);
    const [category,setCategory] =useState([])

    const handleCloseAddCategory = () => {
        setShowAddCategory(false)
        setCategory([])
    };
    const handleShowAddCategory = (category) => {
        setShowAddCategory(true)
        setCategory(category)
    };
    const deleteExam =(categoryId)=>{
        axiosConfig.delete(`on-board-slider/delete-on-board-slider/${categoryId}`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
        }).then(res=>{
            toast.success('Onboard Slider deleted successfully')
            getData()
        }).catch(err=>{
            // setIsSubmitting(false)
            let errors = err?.response?.data?.data?.[0]
            console.log(err?.response?.data?.data)
            Object.keys(errors)?.forEach(error=>{
                toast.error(errors?.[error])
            })
        })
    }
  return (
    <>
    <div className='table-responsive'>
        <table className={`${styles['table']} table`}>
            <thead>
                <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Image</th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Show Order Number</th>
                    {/* <th scope="col">Update Status</th> */}
                    <th scope="col">Actions</th>
                    {/* <th scope="col">Created At</th> */}
                </tr>
            </thead>
            <tbody>
                {
                    !isLoadingData &&
                        tableData && tableData.map(exam=>(
                            <tr>
                                <th scope="row">{exam?.item_number}</th>
                                <td>
                                    <div className={styles['table__user-img-wrapper']}>
                                        <img alt='category' src={exam?.image?exam?.image:ExamIcon} className={styles['table__user-img']}/>
                                        
                                    </div>
                                </td>
                                <td>
                                    <div className={styles['table__user-group']}>{exam?.title}</div>
                                </td>
                                <td>
                                    <div className={styles['table__user-group']}>{exam?.description}</div>
                                </td>
                                <td>
                                    <div className={styles['table__user-group']}>{exam?.order_number?exam?.order_number:'---'}</div>
                                </td>
                                <td>
                                    <div className={styles['table__user-action-cont']}>
                                        <button className={styles['table__user-action-button']} onClick={()=>{handleShowAddCategory(exam)}}>
                                            <EditIcon className={`${styles['table__user-action-icon']} ${styles['table__user-action-icon--edit']}`}/>
                                        </button>
                                        
                                        <button className={styles['table__user-action-button']}  onClick={()=>{deleteExam(exam.id)}}>
                                            <DeleteIcon className={`${styles['table__user-action-icon']} ${styles['table__user-action-icon--delete']}`}/>
                                        </button>
                                    </div>
                                </td>
                                {/* <td>
                                    <div className={styles['table__user-group']}>{exam.created_at}</div>
                                </td> */}
                            </tr>
                        ))
                }
            </tbody>
        </table>
        {
            isLoadingData && <TableLoading/>
        }
        <AddEditOnboardSliderModal showAddCategory={showAddCategory} handleCloseAddCategory={handleCloseAddCategory} category={category} getData={getData}/>
    </div>
    </>
  )
}

export default PortalOnboardSliderTable